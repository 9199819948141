var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"eb3982cacaa2cec274c263f038c6768783035d3f"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init } from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const TRACE_SAMPLE_RATE = 0.01; // Capture 1%
const PRICE_ENGINE_WEB_DSN =
  'https://50b32b159d044d329ba77c68cb2d7926@o1007889.ingest.sentry.io/6718146';

init({
  dsn: SENTRY_DSN || PRICE_ENGINE_WEB_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: TRACE_SAMPLE_RATE,
  debug: false,
  environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  beforeSend(event) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log('Skip Sentry Error Reporting. NODE_ENV:', process.env.NODE_ENV);
      return null;
    }

    return event;
  },
});
